<template>
  <div>
    <div class="left">
      <div class="label" style="margin-right: 8px">计划年份</div>
      <a-button style="text-align: left; width: 160px">{{ year+'年'}}</a-button>

      <div class="label" style="margin-right: 8px;margin-left: 24px">填报人</div>
      <a-button style="text-align: left; width: 160px">{{ name }}</a-button>

      <div style="margin-left: auto">
        <a-space>
          <MonthSelector :disabled="disabled" @change="onSelectMonth" :list="selectedMonth" />
          <ProjectSelector :disabled="disabled" @change="onSelectProject" />
        </a-space>
      </div>
    </div>

    <Padding />

    <a-table bordered :data-source="totalList" :loading="loading" :pagination="false">
      <a-table-column key="projectDesignCode" title="设计编号" data-index="projectDesignCode"></a-table-column>
      <a-table-column key="projectName" title="项目名称" data-index="projectName"></a-table-column>
      <a-table-column key="productionUnitName2" title="部门" data-index="productionUnitName2"></a-table-column>
      <a-table-column
        key="productionMasterName"
        title="生产负责人"
        data-index="productionMasterName"
        align="center"
      ></a-table-column>
      <a-table-column key="projectAmount" title="项目金额(万元)" align="right">
        <template slot-scope="text">
          <span v-if="text.total"></span>
          <Money v-else :money="text.projectAmount" />
        </template>
      </a-table-column>
      <a-table-column key="progress" title="累计项目进度" align="center">
        <template slot-scope="text">
          <div v-if="typeof text.progress === 'number'" class="between">
            <a-progress :percent="typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress" :showInfo="false" />
            <span style="color: rgba(0, 0, 0, 0.45); width: 50px">{{ typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress }}%</span>
          </div>
          <div v-else></div>
        </template>
      </a-table-column>

      <a-table-column-group key="group" v-if="columns.length > 0" :title="'计划进度'">
        <a-table-column
          v-for="item in columns"
          :key="item.date"
          :title="renderMonth( item.date)"
          align="center"
        >
          <template slot-scope="text, record, index">
            <span
              v-if="Array.isArray(text.hisList) && text.hisList.find(t => t.date === item.date)"
            >
              <span v-if="disabled">
                <span v-if="text.total">
                  <Money :money="text.hisList.find(t => t.date === item.date).proportion" />
                </span>
                <span class="center" v-else>
                  <span>{{text.hisList.find(t => t.date === item.date).proportion}}%</span>
                  <a-icon
                    type="arrow-up"
                    style="color: red"
                    v-if="renderArrow(text.hisList, item.date) === 'up'"
                  />
                  <a-icon
                    type="arrow-down"
                    style="color: green"
                    v-if="renderArrow(text.hisList, item.date) === 'down'"
                  />
                </span>
              </span>

              <span v-else>
                <span v-if="text.total">
                  <Money :money="text.hisList.find(t => t.date === item.date).proportion" />
                </span>

                <span class="center" v-else>
                  <a-input-number
                    size="small"
                    style="width: 60px;margin-right: 4px"
                    :formatter="$ratioFormatter"
                    :min="0"
                    :max="100"
                    :value="text.hisList.find(t => t.date === item.date).proportion"
                    @change="value => onChange(value, item.date, index)"
                    :disabled="text.disabled"
                  />
                  <a-icon
                    type="arrow-up"
                    style="color: red"
                    v-if="renderArrow(text.hisList, item.date) === 'up'"
                  />
                  <a-icon
                    type="arrow-down"
                    style="color: green"
                    v-if="renderArrow(text.hisList, item.date) === 'down'"
                  />
                </span>
              </span>
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
      </a-table-column-group>

      <a-table-column key="remarks" title="备注">
        <template slot-scope="text">
          <span v-if="disabled">{{ text.remarks }}</span>
          <span v-else>
            <span v-if="text.total"></span>
            <a-textarea v-else v-model="text.remarks" placeholder="请输入" size="small" />
          </span>
        </template>
      </a-table-column>
      <a-table-column key="control" title="操作" align="center" width="60px" v-if="!disabled">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click="remove(index)" v-if="!text.disabled && !text.total">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import ProjectSelector from "./project-selector.vue";
import MonthSelector from "./month-selector.vue";

import moment from "moment";
import accurate from "accurate";

export default {
  props: {
    year: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ProjectSelector,
    MonthSelector
  },

  data() {
    return {
      selectedMonth: []
    };
  },

  computed: {
    columns() {
      let arr = [];
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        if (Array.isArray(item.hisList)) {
          if (item.hisList.length > arr.length) {
            arr = item.hisList;
          }
        }
      }
      return arr;
    },

    totalList() {
      if (this.list.length > 0) {
        const hisList = this.list[0].hisList
          .map(item => item.date)
          .map(date => {
            let total = 0;

            this.list.forEach(element => {
              const projectAmount = element.projectAmount || 0;
              const e = element.hisList.find(e => e.date === date);
              if (e) {
                const result = accurate.expr(
                  `${projectAmount}*${e.proportion}*${0.01}`
                );
                total = accurate.expr(`${total}+${result}`);
              }
            });

            return {
              date,
              proportion: total
            };
          });

        return [
          ...this.list,
          {
            key: "total",
            projectDesignCode: "合计(万元)",
            total: true,
            hisList
          }
        ];
      } else {
        return [];
      }
    }
  },

  methods: {
    onSelectMonth(arr) {
      this.selectedMonth = arr;
      this.setList();
    },

    setList() {
      this.list.forEach(item => {
        const hisList = item.hisList.map(item => {
          return {
            ...item
          };
        });

        this.selectedMonth.forEach(month => {
          const date = moment(new Date(this.year, month - 1)).format("YYYY-MM");
          if (!hisList.find(e => e.date === date)) {
            hisList.push({
              date,
              proportion: 0
            });
          }
        });

        item.hisList.forEach(h => {
          const flag = this.selectedMonth.find(month => {
            const date = moment(new Date(this.year, month - 1)).format(
              "YYYY-MM"
            );
            return h.date === date;
          });
          if (!flag) {
            const index = hisList.findIndex(his => his.date === h.date);
            hisList.splice(index, 1);
          }
        });

        hisList.sort((x, y) => {
          const xMonth = parseInt(moment(x.date).format("M"));
          const yMonth = parseInt(moment(y.date).format("M"));
          return xMonth - yMonth;
        });

        item.hisList = hisList;
      });

      this.$emit("change", this.list);
    },

    onSelectProject(project) {
      const item = this.list.find(item => item.projectId === project.id);
      if (item) {
        this.$message.error("请勿重复添加");
        return;
      }

      this.list.push({
        key: Date.now().toString() + Math.random(),

        projectDesignCode: project.designCode,
        projectName: project.name,

        projectId: project.id,
        contractId: project.contractId,

        productionUnitName2: project.contract
          ? project.contract.productionUnitName2
          : "",
        productionMasterName: project.contract
          ? project.contract.productionMasterName
          : "",

        projectAmount: project.projectAmount,
        progress: project.progress,
        remarks: "",

        hisList: []
      });

      this.setList();
    },

    remove(index) {
      this.list.splice(index, 1);
      this.$emit("change", this.list);
    },

    onChange(value, date, index) {
      const item = this.list[index];

      const idx = item.hisList.findIndex(item => item.date === date);
      const element = item.hisList[idx];

      const hisList = [...item.hisList];
      hisList.splice(idx, 1, {
        ...element,
        proportion: value
      });

      this.list.splice(index, 1, {
        ...item,
        hisList
      });
    },

    renderArrow(hisList, date) {
      const first = hisList[0];
      if (first.date === date) {
        return "";
      } else {
        const element = hisList.find(item => item.date === date);
        if (element) {
          if (element.proportion > first.proportion) {
            return "up";
          } else if (element.proportion < first.proportion) {
            return "down";
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },

    renderMonth(str) {
      return moment(str).format("M月");
    }
  }
};
</script>