<template>
  <div>
    <Pane />
    <a-card class="container">
      <MonthTable
        v-if="detail.type === 'projectPlanMonth'"
        :year="year"
        :month="month"
        :date="date"
        :name="detail.fillingUserName"
        :loading="loading"
        :list="list"
        :disabled="true"
      />
      <YearTable
        v-if="detail.type === 'projectPlanYear'"
        :year="year"
        :name="detail.fillingUserName"
        :loading="loading"
        :list="list"
        :disabled="true"
      />
    </a-card>
  </div>
</template>

<script>
import MonthTable from "./components/month-table.vue";
import YearTable from "./components/year-table.vue";
import request from "@/api/request";

function fetchDetail(data) {
  return request({
    url: "/project-service/projectPlan/detail/" + data.id,
    method: "post"
  });
}

export default {
  components: {
    MonthTable,
    YearTable
  },

  data() {
    return {
      year: 0,
      month: 0,
      date: 0,
      loading: false,
      detail: {},
      list: []
    };
  },

  mounted() {
    const { id } = this.$route.query;
    if (!id) return;
    this.loading = true;
    fetchDetail({
      id
    })
      .then(res => {
        this.detail = res || {};

        this.list = Array.isArray(this.detail.subList)
          ? this.detail.subList.map(item => {
              return {
                ...item,
                key: item.id,

                productionUnitName2: item.contract
                  ? item.contract.productionUnitName2
                  : "",
                productionMasterName: item.contract
                  ? item.contract.productionMasterName
                  : "",

                disabled: true // 不可以删除，不可以修改进度，可以修改备注
              };
            })
          : [];

        if (this.detail.type === "projectPlanYear") {
          this.year = this.detail.year;
        } else {
          if (
            Array.isArray(this.detail.subList) &&
            this.detail.subList.length > 0
          ) {
            const hisList = this.detail.subList[0].hisList || [];
            const last = hisList[hisList.length - 1];
            const obj = new Date(last.date);
            this.year = obj.getFullYear();
            this.month = obj.getMonth();
            this.date = obj.getDate();
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>