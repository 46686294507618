<template>
  <span>
    <a-button :disabled="disabled" @click="onClick">添加月份</a-button>

    <a-modal title="选择月份" :visible="visible" @cancel="cancel" :footer="null" width="800px">
      <a-button
        @click="selectAll"
        style="margin-bottom: 16px"
      >{{ selected.length === 12 ? '全不选' : '全选' }}</a-button>
      <div style="padding-bottom: 40px">
        <a-checkbox-group v-model="selected">
          <a-checkbox v-for="item in 12" :key="item" :value="item">{{item}}月</a-checkbox>
        </a-checkbox-group>
      </div>

      <Padding size="large" />

      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" @click="add">添加</a-button>
        </a-space>
      </div>
    </a-modal>
  </span>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      visible: false
    };
  },

  methods: {
    selectAll() {
      if (this.selected.length === 12) {
        this.selected = [];
      } else {
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        this.selected = arr;
      }
    },

    onClick() {
      this.selected = [...this.list];
      this.visible = true;
    },
    add() {
      if (this.selected.length === 0) {
        this.$message.error("请选择月份");
        return;
      }
      this.$emit("change", [...this.selected]);
      this.cancel();
      this.selected = [];
    },

    cancel() {
      this.visible = false;
    }
  }
};
</script>